import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Link, useLocation } from 'react-router-dom';
import ASHCareLogoDesktop from '../../../assets/ASHCareLogo_desktop.svg';
import ASHCareLogoMobile from '../../../assets/ASHCareLogo_mobile.svg';
import emdDesktop from '../../../assets/anthem/EMD-desktop.svg';
import emdMobile from '../../../assets/anthem/EMD-mobile.svg';

import useSessionInfo from '../../../data/queryHooks/useSessionInfo';
import useIsMobile, {
	useIsXsMobile,
} from '../../../utils/customHooks/useIsMobile';
import useCustomHomePage from '../../../data/queryHooks/useCustomHomePage';
import useClientData from '../../../data/queryHooks/useClientData';
import {
	getClientCodeFromPath,
	getProgramFromPath,
} from '../../../utils/helperFunctions';
import { SessionStorageKey } from '../../../scripts/miscConstants';
import { useState } from 'react';

// This will link to the home page if the user's not logged in or the dashboard if the user is logged in.
const HeaderLogo = () => {
	const { pathname } = useLocation();

	const clientCode = getClientCodeFromPath(pathname);
	const program = getProgramFromPath(pathname);

	const { isLoggedIn, clientLogo, clientLogoXs, isWhiteLabeling } =
		useSessionInfo();

	const isAnthemPreLogin = pathname === '/empowered-decisions/anthem';

	const isMobile = useIsMobile();
	const isXsMobile = useIsXsMobile();
	const { clientCodes, clientProgramSlugs } = useClientData();
	const [hasLogoError, setHasLogoError] = useState(false);
	const isValidClientProgram = clientProgramSlugs
		?.find(item => item.clientCode === clientCode)
		?.programSlugs.includes(program);

	if (
		!isLoggedIn &&
		clientCodes?.includes(clientCode) &&
		isValidClientProgram
	) {
		sessionStorage.setItem(SessionStorageKey.clientCode, clientCode);
		sessionStorage.setItem(SessionStorageKey.clientProgram, program);
	} else if (
		window.location.pathname === '/' &&
		sessionStorage.getItem(SessionStorageKey.clientCode) &&
		sessionStorage.getItem(SessionStorageKey.clientProgram)
	) {
		sessionStorage.removeItem(SessionStorageKey.clientCode);
		sessionStorage.removeItem(SessionStorageKey.clientProgram);
	}

	const { colorLogo, colorLogoXs, isCobranded, altTextLogo } =
		useCustomHomePage(
			sessionStorage.getItem(SessionStorageKey.clientCode),
			clientCodes,
			isLoggedIn,
			null,
			sessionStorage.getItem(SessionStorageKey.clientProgram)
		);

	const customClientLogo = isLoggedIn ? clientLogo : colorLogo;
	const customClientLogoXs = isLoggedIn ? clientLogoXs : colorLogoXs;
	const isWhiteLabel = isLoggedIn ? isWhiteLabeling : !isCobranded;
	const altText = isLoggedIn ? '' : altTextLogo;

	const handleClientLogoError = () => {
		setHasLogoError(true);
	};

	const ASHCareLogo = (
		<Link
			to="/"
			reloadDocument={pathname === '/'}
			aria-label="back to ASHCare Home"
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<img
				src={
					isMobile && customClientLogo ? ASHCareLogoMobile : ASHCareLogoDesktop
				}
				alt="ASH Care Logo"
			/>
		</Link>
	);

	const WhiteLabeledLogo = (
		<Link
			to="/"
			reloadDocument={pathname === '/'}
			aria-label="back to Home"
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<img
				src={isXsMobile ? customClientLogoXs : customClientLogo}
				alt={altText}
				style={{ maxHeight: '48px', maxWidth: '12rem' }}
				onError={handleClientLogoError}
			/>
		</Link>
	);

	const EMDLogo = (
		<>
			<Divider orientation="vertical" variant="middle" flexItem />
			<Link
				to="/"
				reloadDocument={pathname === '/'}
				aria-label="back to Home"
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<img
					src={isXsMobile ? emdMobile : emdDesktop}
					alt="Empowered Decisions"
					style={{ maxHeight: '48px', maxWidth: '12rem' }}
					onError={handleClientLogoError}
				/>
			</Link>
		</>
	);

	const MainLogo =
		isWhiteLabel && customClientLogo && !hasLogoError
			? WhiteLabeledLogo
			: ASHCareLogo;

	const handleCustomLogo = () => {
		if (!isWhiteLabel && customClientLogo && !hasLogoError) {
			return (
				<>
					<Divider orientation="vertical" flexItem />
					<Box
						component="img"
						src={isXsMobile ? customClientLogoXs : customClientLogo}
						// TODO: Add alt attribute via customizations
						alt={altText}
						sx={{
							maxWidth: { xs: '12rem', sm: '19rem', md: '27rem' },
							maxHeight: '48px',
							minHeight: '38px',
						}}
						onError={handleClientLogoError}
					/>
				</>
			);
		}
		return null;
	};

	return (
		<>
			{MainLogo}
			{isAnthemPreLogin && EMDLogo}
			{handleCustomLogo()}
		</>
	);
};

export default HeaderLogo;
