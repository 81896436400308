import { useQuery } from 'react-query';
import graphqlRequestClient from '../graphqlRequestClient';
import { QueryKey } from './QueryKeys';
import { previewVariable } from '../../scripts/graphqlQueries';
import useSessionInfo from './useSessionInfo';

const useHomePage = query => {
	const { isLoggedIn } = useSessionInfo();
	const { data } = useQuery(
		[QueryKey.GET_HOME_PAGE, query],
		async () => {
			// for some reason useQuery can't catch graphql request error
			// have to add below to catch error
			try {
				return await graphqlRequestClient.request(query, previewVariable);
			} catch (error) {
				return error;
			}
		},
		{ enabled: !isLoggedIn }
	);

	return {
		data,
	};
};

export default useHomePage;
