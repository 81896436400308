import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import useNavRoutes from '../../../data/queryHooks/useNavRoutes';
import NavigationItem from './NavigationItem';
import { useTheme } from '@mui/material/styles';
import {
	topBarHeight,
	topBarMobileHeight,
} from '../../../scripts/miscConstants';
import { NavButtonMapping } from '../buttons/NavButtons';
import useIsMobile from '../../../utils/customHooks/useIsMobile';
import { alpha } from '@mui/system/colorManipulator';
import { bool } from 'prop-types';
import { QueryKey } from '../../../data/queryHooks/QueryKeys';
import { useQuery } from 'react-query';
import { fetchSessionInfo } from '../../../data/apiCalls';
import Skeleton from '@mui/material/Skeleton';

const bottomBarHeight = '72px';

const BottomBar = ({ showHeader }) => {
	const { secondNavBarRoutes } = useNavRoutes();
	const { palette } = useTheme();
	const activeLinkStyles = {
		borderBottom: `3px solid ${palette.secondary.main}`,
		color: 'secondary.main',
	};

	const { data: userSessionData } = useQuery(
		QueryKey.GET_SESSION_INFO,
		fetchSessionInfo
	);
	const isMobile = useIsMobile();
	const margin = () => {
		if (showHeader) {
			return isMobile ? topBarMobileHeight : topBarHeight;
		}
		return 0;
	};

	if (secondNavBarRoutes?.length && userSessionData) {
		return (
			secondNavBarRoutes?.length &&
			userSessionData && (
				<AppBar
					variant="outlined"
					elevation={0}
					color="default"
					sx={{ mt: margin(), backgroundColor: palette.background.default }}
					// We need to make sure we don't have more than one "header" component for A11y
					component="nav"
				>
					<Container>
						<Toolbar sx={{ minHeight: `${bottomBarHeight} !important` }}>
							{secondNavBarRoutes.map(secondNavBarLink => (
								<NavigationItem
									key={secondNavBarLink.label}
									navLink={secondNavBarLink}
									defaultStyles={{
										height: bottomBarHeight,
										borderRadius: 0,
										px: 3,
										color: 'text.primary',
										fontWeight: 'fontWeightBold',
										// We want the hover styles to include all active link styles but also add on a highlight color
										'&:hover': {
											backgroundColor: alpha(palette.secondary.main, 0.04),
											...activeLinkStyles,
										},
									}}
									activeLinkStyles={activeLinkStyles}
									{...NavButtonMapping}
								>
									{secondNavBarLink.label}
								</NavigationItem>
							))}
						</Toolbar>
					</Container>
				</AppBar>
			)
		);
	}

	return (
		<>
			<Skeleton />
			<Skeleton />
		</>
	);
};

BottomBar.propTypes = {
	showHeader: bool,
};

export default BottomBar;
