import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { NavLinksType } from '../../../scripts/customPropTypes';
import Link from '@mui/material/Link';
import { Link as ReactLink, useLocation } from 'react-router-dom';

const UserProfileDropDown = ({ profileRoutes }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { pathname } = useLocation();

	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleOpen}
				color="inherit"
				aria-label="user profile drop down"
				data-testid="user-profile"
			>
				<PersonSharpIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{profileRoutes.map(link => {
					const component = link.isReactPage ? (
						<MenuItem
							component={ReactLink}
							to={link.href}
							reloadDocument={pathname === link.href}
							key={link.href}
						>
							{link.label}
						</MenuItem>
					) : (
						<MenuItem
							component={Link}
							href={link.href}
							key={link.href}
							data-testid={link.label}
						>
							{link.label}
						</MenuItem>
					);
					return component;
				})}
			</Menu>
		</>
	);
};

UserProfileDropDown.propTypes = NavLinksType;

export default UserProfileDropDown;
