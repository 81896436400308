import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This component will cause the app to scroll to the top of the page anytime a new route is loaded.
// TODO: Check for options built into react-router
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return null;
};

export default ScrollToTop;
