import { Route, Routes, Outlet } from 'react-router-dom';
import useNavRoutes from '../../data/queryHooks/useNavRoutes';
import { TermsAgreementContent } from '@ashn/customerservice-react/TermsAgreement';
import { func } from 'prop-types';
import ScrollToTop from './ScrollToTop';
import PaddedContainer from './containers/PaddedContainer';
import PageSEO from './PageSEO';
import { product, Programs } from '../../scripts/miscConstants';
import useHomePage from '../../data/queryHooks/useHomePage';
import { GetHomePageQuery } from '../../scripts/graphqlQueries';
import { Suspense, lazy } from 'react';
import ActionPlanRoutes from '@ashn/action-plan-react/Routes';
import useSessionInfo from '../../data/queryHooks/useSessionInfo';
import useClientData from '../../data/queryHooks/useClientData';
import getLiveSessionChildRoutes from '@ashn/live-session-react/Routes';
import ProtectedRoute from './ProtectedRoute';
import getApiEnvironment from '../../utils/environmentHelpers/getAPIEnvironments';
import Layout from './Layout';

const PaymentPage = lazy(() => import('../memberSessions/PaymentPage'));

const WaitingRoomPage = lazy(() => import('../memberSessions/WaitingRoomPage'));

const AppointmentDetailsPage = lazy(() =>
	import('../memberSessions/AppointmentDetailsPage')
);

const PaymentHistoryPage = lazy(() => import('../billing/PaymentHistoryPage'));

const PaymentAndBilling = lazy(() =>
	import('../billing/PaymentAndBillingPage')
);

const ProgramDetailPage = lazy(() =>
	import('../programDetailPage/ProgramDetailPage')
);
const ProgramDetailPageAnthemPreLogin = lazy(() =>
	import('../programDetailPage/anthem/ProgramDetailPageAnthemPreLogin')
);

const ProgramDetailPageAnthemPostLogin = lazy(() =>
	import(
		'../dashboard/VirtualFitnessAndRecovery/anthem/ProgramDetailPageAnthemPostLogin'
	)
);

const GenericStorePage = lazy(() =>
	import('../genericStorePage/GenericStorePage')
);

const ReactRoutes = ({ acceptTerms }) => {
	const {
		homePageRoute,
		topNavBarRoutes,
		secondNavBarRoutes,
		errorPages,
		footerMenu,
	} = useNavRoutes();

	const { ssId, memberPrograms, paymentHistoryBlob, phoneNumber } =
		useSessionInfo();

	// We only want react-router tags for React pages internal to this app, not for Ember
	// pages or external links. The AshCare React pages will have a non-null component.
	const reactLinks = [
		...homePageRoute,
		...topNavBarRoutes,
		...secondNavBarRoutes,
		...errorPages,
		...footerMenu,
	].filter(link => link.component);

	const { data } = useHomePage(GetHomePageQuery);
	const { clientData } = useClientData();

	return (
		<>
			{/* Scroll to the top of the page anytime a new route is loaded */}
			<ScrollToTop />
			<Routes>
				<Route element={<Layout showHeader showFooter />}>
					<Route
						key="/empowered-decisions/anthem"
						path="/empowered-decisions/anthem"
						element={<ProgramDetailPageAnthemPreLogin />}
					/>
				</Route>

				<Route element={<Layout showHeader showNav showFooter />}>
					{reactLinks.map(link => (
						<Route
							key={link.href}
							path={`/${link.href}`}
							element={link.component}
						/>
					))}

					{/* add routes for custom clientcode program detail pages */}
					{/* loop through custom client only */}

					{clientData?.map(client =>
						client.customProgramCardsCollection?.items?.map(program => {
							const slug =
								program.overridePage?.slug || program.detailPage?.slug;
							const pageId =
								program.overridePage?.sys?.id || program.detailPage?.sys?.id;
							return (
								slug && (
									<Route
										key={`${slug}/${client.clientCode}`}
										path={`${slug}/${client.clientCode}`}
										element={<ProgramDetailPage pageId={pageId} />}
									/>
								)
							);
						})
					)}
					{/* add routes for program detail pages */}
					{data?.ashCareHomePageCollection?.items[0]?.programCardsCollection?.items.map(
						program =>
							program.detailPage.isRoutable &&
							program.detailPage.slug && (
								<Route
									key={program.detailPage.slug}
									path={program.detailPage.slug}
									element={
										<ProgramDetailPage pageId={program.detailPage.sys.id} />
									}
								/>
							)
					)}

					{/* Note: add protected route componnets under this condition */}

					{/* Terms Agreement route: Displayed upon login */}
					<Route
						path="termsAgreement"
						element={
							<ProtectedRoute>
								<PageSEO
									pageTitle="Latest Terms & Conditions"
									description="Read and Agree ASHCare’s most up-to-date terms, conditions, and disclaimers."
								/>

								<PaddedContainer>
									<TermsAgreementContent
										apiEnv={getApiEnvironment()}
										ssId={ssId}
										hasOwnProvider
										product={product}
										termsTitleAfterUpdate=""
										redirectUrlAccept=""
										acceptTerms={acceptTerms}
										redirectUrlDecline="/auth/logout"
										serverErrorPageUrl="/500"
									/>
								</PaddedContainer>
							</ProtectedRoute>
						}
					/>

					<Route
						path="/memberSessions/waitingRoom/:appointmentId"
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<Suspense>
									<WaitingRoomPage ssId={ssId} phoneNumber={phoneNumber} />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="/memberSessions/payment/:appointmentId"
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<Suspense>
									<PaymentPage ssId={ssId} phoneNumber={phoneNumber} />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="/memberSessions/appointment"
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<Suspense>
									<AppointmentDetailsPage />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="actionPlan/*" // If you change this path, update all "pathToActionPlanRoutes" props in this project to match
						element={
							<PaddedContainer>
								<ProtectedRoute
									isAuthorized={memberPrograms.includes(
										Programs.VirtualPhysicalTherapyAndRehabilitation
									)}
								>
									<ActionPlanRoutes
										ssId={ssId}
										apiEnv={getApiEnvironment()}
										navigateToResourceLibrary={resourceId => {
											window.location.assign(
												`/resourcelibrary/workouts/${resourceId}`
											);
										}}
										pathToActionPlanRoutes="/actionPlan"
									/>
								</ProtectedRoute>
							</PaddedContainer>
						}
					/>
					<Route
						path="payment/history/*"
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<Suspense>
									<PaymentHistoryPage
										ssId={ssId}
										paymentHistoryBlob={paymentHistoryBlob}
									/>
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="payment/billing/*"
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<Suspense>
									<PaymentAndBilling
										ssId={ssId}
										paymentHistoryBlob={paymentHistoryBlob}
									/>
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route>
						<Route
							path="/"
							element={
								<ProtectedRoute
									isAuthorized={memberPrograms.includes(
										Programs.VirtualFitnessAndRecovery
									)}
								>
									<ProgramDetailPageAnthemPostLogin />
								</ProtectedRoute>
							}
						/>
					</Route>

					<Route path="generic-store" element={<GenericStorePage />} />
				</Route>

				{/* Below route(s) will not have header, nav and footer */}
				<Route element={<Layout />}>
					<Route
						element={
							<ProtectedRoute
								isAuthorized={memberPrograms.includes(
									Programs.VirtualPhysicalTherapyAndRehabilitation
								)}
							>
								<PageSEO
									pageTitle="Live Synchronous Session"
									description="Live synchronous video session between member and provider"
								/>
								<Outlet />
							</ProtectedRoute>
						}
					>
						{getLiveSessionChildRoutes(getApiEnvironment(), ssId)}
					</Route>
				</Route>
			</Routes>
		</>
	);
};

ReactRoutes.propTypes = {
	acceptTerms: func.isRequired,
};

export default ReactRoutes;
