import Skeleton from '@mui/material/Skeleton';
import PaddedContainer from './containers/PaddedContainer';

const PageSkeleton = () => (
	<PaddedContainer data-testid="skeletonLoading">
		<Skeleton sx={{ fontSize: '12px' }}    />
		<Skeleton sx={{ fontSize: '12px', mt: 1 }} />
		<Skeleton sx={{ fontSize: '12px', mt: 1 }} />
		<Skeleton variant="rounded" sx={{ mt: 2 }} height={554} />
	</PaddedContainer>
);

export default PageSkeleton;
