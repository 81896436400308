import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import QueryClientProvider from './components/shared/QueryClientProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HelmetProvider } from 'react-helmet-async';
import getEnvConfig from './utils/environmentHelpers/getEnvConfig';
import getApiEnvironment from './utils/environmentHelpers/getAPIEnvironments';

if (
	process.env.NODE_ENV === 'development' &&
	!process.env.REACT_APP_PLAYWRIGHT
) {
	// eslint-disable-next-line global-require
	const { worker } = require('./mocks/browser');
	worker.start();
}

const appInsights = new ApplicationInsights({
	config: {
		connectionString: getEnvConfig(
			getApiEnvironment(),
			'applicationInsightConnectionString'
		),
		enableAutoRouteTracking: true,
	},
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router>
		<QueryClientProvider>
			<React.StrictMode>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</React.StrictMode>
		</QueryClientProvider>
	</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
