import { string, bool, any } from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useSessionInfo from '../../data/queryHooks/useSessionInfo';

/*
 * This component can be used to automatically redirect a user to the specified relative URL path if a condition is met.
 * This component will also redirect user to the auth/login page with proper redirect query parameter if they are not logged in
 * E.g.
 *
 * 	<Route
 * 		path="actionPlan"
 * 		element={
 * 			<PaddedContainer>
 * 				<ProtectedRoute isAuthorized={false} redirectTo="/identity/login">
 * 					<Outlet />
 * 				</ProtectedRoute>
 * 			</PaddedContainer>
 * 			}
 * 	>
 */
const ProtectedRoute = ({ children, isAuthorized, redirectTo }) => {
	const location = useLocation();
	const { isLoggedIn } = useSessionInfo();

	// redirect to login page if not logged in - ember SPA
	if (!isLoggedIn) {
		return window.location.assign(
			`/identity/login/?redirect=${location.pathname}`
		);
	}

	// User is logged in, but redirect if they're not authorized to see this specific route
	if (!isAuthorized) {
		return <Navigate to={redirectTo} state={{ from: location }} replace />;
	}

	return children;
};

ProtectedRoute.propTypes = {
	/*
	 * Boolean: If isAuthorized is a falsy value, then the user will be redirected to the relative URL path specified by the redirectTo prop
	 */
	isAuthorized: bool,
	/*
	 * String: the relative URL path the user should be redirected to if they are not authorized to see this route.
	 */
	redirectTo: string,
	children: any.isRequired,
};

ProtectedRoute.defaultProps = {
	redirectTo: '/404',
	isAuthorized: true,
};

export default ProtectedRoute;
