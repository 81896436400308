const envConfigs = {
	dev: {
		applicationInsightConnectionString:
			'InstrumentationKey=9a164bfe-78c1-4fea-86c5-96bd1f0b6cea;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com',
		contentfulPreview: false,
	},
	tst: {
		applicationInsightConnectionString:
			'InstrumentationKey=af1fba5c-150c-4fbf-9a79-f4a6dc5c3583;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com',
		contentfulPreview: false,
	},
	stg: {
		applicationInsightConnectionString:
			'InstrumentationKey=c2e86e6b-0edb-44be-82e4-fd6cbe549e08;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com',
		contentfulPreview: true,
	},
	prod: {
		applicationInsightConnectionString:
			'InstrumentationKey=59873e27-d5d9-4eea-a17b-3ddc874c5171;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com',
		contentfulPreview: false,
	},
};

// function used to get different config ids based on the env passed in
// see helper util getEnvironment for how to get the env we are currently in
const getEnvConfig = (env, configName) => {
	if (!envConfigs[env]) {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line
			console.warn('config doesnt exist');
		}
		return null;
	}
	return envConfigs[env][configName];
};

export default getEnvConfig;
