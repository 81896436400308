export const languageAssistLangs = [
	{
		language: 'English',
		// empty string since additional text for screen readers is not needed since it is english
		srText: '',
	},
	{
		language: 'Español',
		srText: 'Spanish',
	},
	{
		language: '繁體中文',
		srText: 'Chinese',
	},
	{
		language: 'Tagalog',
		srText: '',
	},
	{
		language: 'Tiếng Việt',
		srText: 'Vietnamese',
	},
	{
		language: 'العربية',
		srText: 'Arabic',
	},
	{
		language: 'Français',
		srText: 'French',
	},
	{
		language: '한국어',
		srText: 'Korean',
	},
	{
		language: 'Русский',
		srText: 'Russian',
	},
	{
		language: 'Deutsch',
		srText: 'German',
	},
	{
		language: 'Kreyòl Ayisyen',
		srText: 'French Creole',
	},
	{
		language: 'हिंदी',
		srText: 'Hindi',
	},
	{
		language: 'Português',
		srText: 'Portuguese',
	},
	{
		language: 'Italiano',
		srText: 'Italian',
	},
	{
		language: 'Polski',
		srText: 'Polish',
	},
	{
		language: 'اُردُو',
		srText: 'Farsi',
	},
	{
		language: 'אידיש',
		srText: 'Yiddish',
	},
	{
		language: 'বাংলা',
		srText: 'Bengali',
	},
	{
		language: 'Ελληνικά',
		srText: 'Greek',
	},
	{
		language: 'Shqiptar',
		srText: 'Albanian',
	},
];

export const externalLinkText =
	'Clicking on these links will take you away from ASHCare.com. ASHCare is not responsible for any content or the privacy practices of external websites.';

export const discriminationText =
	'The ASHCare program is a subsidiary of American Specialty Health Incorporated (ASH). ASH complies with applicable Federal civil right laws and does not discriminate on the basis of race, color, national origin, age, disability, or sex. ASHCare, the ASHCare logo, and the A Product of American Specialty Health Logo are trademarks of ASH. Persons pictured are not participants in the ASHCare program.';

export const copyright = `© ${new Date().getFullYear()} American Specialty Health Incorporated (ASH). All rights reserved.`;

export const pdfPath = '/PDF/Language-assistance-ASHCare.pdf';
