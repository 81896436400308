import TopBar from './TopBar';
import BottomBar from './BottomBar';
import Box from '@mui/system/Box';
import { useTheme } from '@mui/material/styles';
import useIsMobile from '../../../utils/customHooks/useIsMobile';
import { bool } from 'prop-types';
import useSessionInfo from '../../../data/queryHooks/useSessionInfo';

const Header = ({showHeader, showNav}) => {
	const { zIndex } = useTheme();
	const isMobile = useIsMobile();
	const { isLoggedIn } = useSessionInfo();

	return (
		<Box
			sx={{
				// Make the header still show when the drawer is open
				zIndex: zIndex.drawer + 1,
				height: '0px',
			}}
		>
			{showHeader && <TopBar />}
			{showNav && !isMobile && isLoggedIn ? <BottomBar showHeader={showHeader} /> : null}
		</Box>
	);
};

Header.propTypes = {
	showHeader: bool,
	showNav: bool,
};

export default Header;
