//  The height of the top AppBar. This needs to be exported separately
//  from the TopBar.js file so it doesn't cause a circular dependency
//  when imported into MobileNavMenu.jsx.
export const topBarHeight = '80px';
export const topBarMobileHeight = '64px';

export const product = 'ASHCare';

export const CareTeamType = {
	Therapist: 'therapist',
	Coach: 'coach',
};

export const SessionStorageKey = {
	clientCode: 'clientCode',
	clientProgram: 'clientProgram',
	userFlow: 'userFlow',
};

export const Programs = {
	VirtualPhysicalTherapyAndRehabilitation:
		'VirtualPhysicalTherapyAndRehabilitation',
	VirtualWellBeingCoaching: 'VirtualWellBeingCoaching',
	VirtualFallPrevention: 'VirtualFallPrevention',
	VirtualFitnessCoaching: 'VirtualFitnessCoaching',
	VirtualFitnessAndRecovery: 'VirtualFitnessAndRecovery',
	AIEnabledMSK: 'AIEnabledMSK',
};
