import { useQuery } from 'react-query';
import { fetchSessionInfo } from '../apiCalls';
import { QueryKey } from './QueryKeys';

// Query for the session info.
const useSessionInfo = () => {
	// StartRegion: Set initial data to an empty object so that we don't have to use optional chaining if the data is not available.
	const { data = {} } = useQuery(QueryKey.GET_SESSION_INFO, fetchSessionInfo);

	const { session = {}, programs = [], flows = [], features = [] } = data;

	const {
		clientLogo,
		clientLogoXs,
		phoneNumbers,
		paymentHistoryBlob,
		ssId = '',
		firstName,
	} = session;

	// EndRegion: Set initial data to an empty object so that we don't have to use optional chaining if the data is not available.

	const isLoggedIn = !!session.ssId;
	const hasProgram = !!programs.length > 0;

	// isCompleted property keeps track of user flows
	const completedFlows = flows.map(flow => ({
		...flow,
		isCompleted: false,
	}));

	return {
		isLoggedIn,
		hasProgram,
		memberPrograms: programs,
		ssId,
		firstName,
		flows: completedFlows,
		phoneNumber: phoneNumbers?.phoneNumber,
		coachingPhoneNumber: phoneNumbers?.coachingPhoneNumber,
		hasDigitalWorkouts: features.includes('DigitalWorkouts'),
		hasOTC: features.includes('OTC'),
		clientLogo: clientLogo?.url,
		clientLogoXs: clientLogoXs?.url,
		isWhiteLabeling: !!clientLogo?.hideSiteLogo,
		paymentHistoryBlob,
	};
};

export default useSessionInfo;
