export const QueryKey = {
	GET_SESSION_INFO: 'GetSessionInfo',
	GET_SPACEX_LAUNCHES: 'GetSpaceXLaunches',
	GET_EMAIL_VERIFICATION_STATUS: 'GetEmailVerificationStatus',
	GET_SSO_REDIRECT: 'GetSSORedirect',
	GET_HOME_PAGE: 'GetHomePage',
	GET_CARE_TEAM: 'GetCareTeam',
	GET_PROGRAM_DETAIL_PAGE: 'GetProgramDetailPage',
	GET_CARE_TEAM_INFO: 'GetCareTeamInfo',
	GET_CLIENT_DATA: 'GetClientData',
	GET_CUSTOM_HOME_PAGE: 'GetCustomHomePage',
};
