import {
	QueryClientProvider as ReactQueryProvider,
	QueryClient,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { element, oneOfType, arrayOf } from 'prop-types';
import ServerStateProvider from './ServerStateProvider';

const normalOptions = {
	defaultOptions: {
		queries: {
			suspense: true,
			refetchOnWindowFocus: false,
		},
	},
};

const unitTestOptions = {
	defaultOptions: {
		queries: {
			...normalOptions.defaultOptions.queries,
			retry: false,
		},
	},
};

// This component sets up react-query
const QueryClientProvider = ({ children }) => {
	const queryClientOptions =
		process.env.NODE_ENV === 'test' ? unitTestOptions : normalOptions;

	const queryClient = new QueryClient(queryClientOptions);

	return (
		<ReactQueryProvider client={queryClient}>
			<ServerStateProvider>{children}</ServerStateProvider>
			{process.env.NODE_ENV === 'development' && (
				<ReactQueryDevtools initialIsOpen={false} />
			)}
		</ReactQueryProvider>
	);
};

QueryClientProvider.propTypes = {
	children: oneOfType([element, arrayOf(element)]),
};

export default QueryClientProvider;
