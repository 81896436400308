import { gql } from 'graphql-request';
import getEnvConfig from '../utils/environmentHelpers/getEnvConfig';
import getApiEnvironment from '../utils/environmentHelpers/getAPIEnvironments';

// This is used for contentful graphql query variable
export const previewVariable = {
	preview: getEnvConfig(getApiEnvironment(), 'contentfulPreview') === 'true',
};

export const GetHomePageQuery = gql`
	query GetHomePage($preview: Boolean) {
		ashCareHomePageCollection(preview: $preview, limit: 1) {
			items {
				pageTitle
				pageDescription
				hero {
					header
					subheader
				}
				programCardsCollection {
					items {
						programName
						header
						image {
							imageXs {
								url
								width
								height
							}
							imageSm {
								url
								width
								height
							}
							imageMd {
								url
								width
								height
							}
							altText
							imageOrientation
						}
						detailPage {
							sys {
								id
							}
							isRoutable
							slug
							programCardbuttonLabel
						}
					}
				}
				customerBenefits {
					headline
					widgetsCollection {
						items {
							icon {
								iconId
								className
							}
							header
							subheader
						}
					}
					buttonLabel
				}
				testimonialsHeader
				memberTestimonialsCollection {
					items {
						avatar {
							url
						}
						altText
						testimony
						author
						company
					}
				}
				clientBenefits {
					headline
					widgetsCollection {
						items {
							icon {
								iconId
								className
							}
							header
							subheader
						}
					}
					buttonLabel
				}
			}
		}
	}
`;

export const GetProgramDetailPageQuery = gql`
	query GetProgramDetailPage($preview: Boolean, $pageId: String!) {
		ashCareProgramDetailPage(preview: $preview, id: $pageId) {
			pageTitle
			pageDescription
			hero {
				text {
					json
				}
				image {
					imageXs {
						url
						width
						height
					}
					imageSm {
						url
						width
						height
					}
					imageMd {
						url
						width
						height
					}
					altText
					imageOrientation
				}
			}
			showRegisterButtonInHero
			callToSchedule {
				json
			}
			video {
				video {
					url
				}
				subtitles {
					url
				}
				poster {
					imageXs {
						url
						width
						height
					}
					imageSm {
						url
						width
						height
					}
					imageMd {
						url
						width
						height
					}
					altText
					imageOrientation
				}
			}
			highlightDetailsWidget {
				text {
					json
				}
				image {
					imageXs {
						url
						width
						height
					}
					imageSm {
						url
						width
						height
					}
					imageMd {
						url
						width
						height
					}
					altText
					imageOrientation
				}
			}
			detailsHeader {
				json
			}
			programDetailsCollection {
				items {
					sys {
						id
					}
					text {
						json
					}
					image {
						imageXs {
							url
							width
							height
						}
						imageSm {
							url
							width
							height
						}
						imageMd {
							url
							width
							height
						}
						altText
						imageOrientation
					}
				}
			}
			eligibilitySection {
				json
			}
			eligibilityButtonLabel
			forEmployers {
				json
			}
			callFooter {
				json
			}
			contactButtonLabel
		}
	}
`;

export const GetClientDataQuery = gql`
	query GetClientData($preview: Boolean) {
		ashCareClientHomePageCollection(preview: $preview) {
			items {
				clientCode
				customProgramCardsCollection(limit: 20) {
					items {
						detailPage {
							slug
							sys {
								id
							}
						}
						overridePage {
							slug
							sys {
								id
							}
						}
					}
				}
			}
		}
	}
`;

export const GetCustomHomePageQuery = gql`
	query GetCustomHomePage($preview: Boolean, $clientCode: String!) {
		ashCareClientHomePageCollection(
			preview: $preview
			limit: 1
			where: { clientCode: $clientCode }
		) {
			items {
				clientCode
				customLogo {
					colorLogo {
						url
					}
					isCobranded
					altText
				}
				customLogoXs {
					colorLogo {
						url
					}
					isCobranded
					altText
				}
				customProgramCardsCollection(limit: 20) {
					items {
						detailPage {
							slug
							sys {
								id
							}
						}
						pageTitle
						pageDescription
						phoneNumber
						customLogo {
							colorLogo {
								url
							}
							isCobranded
							altText
						}
						customLogoXs {
							colorLogo {
								url
							}
							isCobranded
							altText
						}
						overridePage {
							slug
							sys {
								id
							}
						}
					}
				}
			}
		}
	}
`;
