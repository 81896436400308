import { arrayOf, bool, element, shape, string } from 'prop-types';

export const SubLinkType = shape({
	label: string.isRequired,
	href: string.isRequired,
	component: element,
});

export const NavLinkType = shape({
	label: string.isRequired,
	href: string,
	programFeature: string,
	isProtectedPage: bool,
	component: element,
	isExternal: bool,
	subLinks: arrayOf(SubLinkType),
});

export const NavLinksType = {
	navBarLinks: arrayOf(NavLinkType),
};

export const FooterMenuType = arrayOf(
	shape({
		label: string.isRequired,
		href: string.isRequired,
		newTab: bool,
		component: element,
	})
).isRequired;

export const DefaultStoryArgsTypes = {
	/*
	 * Controls whether or not MSW is running. Set to true for live API testing.
	 */
	disableMocks: bool,
	/*
	 * The environment the component is being hosted on such as "prod" or "dev"
	 */
	apiEnv: string,
	/*
	 * Session ID to be added to the header when calling the API.
	 */
	ssId: string,
};
