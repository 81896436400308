import { string, element, oneOfType, object, func } from 'prop-types';
import { NavLinkType } from '../../../scripts/customPropTypes';
import { useMatch } from 'react-router-dom';

const NavigationItem = ({
	navLink,
	ReactComponent,
	EmberComponent,
	ExternalComponent,
	defaultStyles,
	activeLinkStyles,
	children,
	...props
}) => {
	let NavItemComponent;
	const isCurrentTab = useMatch(navLink.href ?? '');
	const activeTabStyles =
		activeLinkStyles && isCurrentTab && navLink.href ? activeLinkStyles : {}; // Only show these styles for the tab we're on.

	// Figure out if it's an Ember link, a React link, or an external link
	if (navLink.isExternal) NavItemComponent = ExternalComponent;
	else if (navLink.component) NavItemComponent = ReactComponent;
	else NavItemComponent = EmberComponent;

	return (
		<NavItemComponent
			href={navLink.href}
			subLinks={navLink.subLinks}
			ssoFeature={navLink.ssoFeature}
			sx={{ ...defaultStyles, ...activeTabStyles }}
			icon={navLink.icon}
			{...props}
		>
			{children}
		</NavItemComponent>
	);
};

NavigationItem.propTypes = {
	navLink: NavLinkType,
	ReactComponent: func,
	EmberComponent: func,
	ExternalComponent: func,
	defaultStyles: object,
	activeLinkStyles: object,
	children: oneOfType([element, string]),
};

export default NavigationItem;
