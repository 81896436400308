import { useQuery } from 'react-query';
import { fetchSessionInfo } from '../apiCalls';
import {
	homePageRoutes,
	topNavBarRoutes,
	profileRoutes,
	secondNavBarRoutes,
	errorPages,
	footerMenu,
} from '../../scripts/routes';
import { Programs } from '../../scripts/miscConstants';
import { QueryKey } from './QueryKeys';

// Query for the routes that the user can access.
const useNavRoutes = () => {
	// This should share the same cache key as useSessionInfo since they're both
	// getting the same exact data back from the server.
	const { data: userSessionData } = useQuery(
		QueryKey.GET_SESSION_INFO,
		fetchSessionInfo
	);
	const isLoggedIn = !!userSessionData?.session?.ssId;

	// Some links should show for everyone, but some should only show to users with
	// a certain benefit, such as Connected or Digital Workouts.
	const userHasProgramFeature = link =>
		link.programFeature
			? userSessionData?.features?.includes(link.programFeature)
			: true;

	const userHasProgram = link => {
		const userPrograms = userSessionData?.programs;

		if (link.program) {
			return userPrograms.includes(link.program);
		}
		return true;
	};

	const setHomeComponentRoute = routes => {
		const userPrimaryProgram = userSessionData?.programs?.find(
			program =>
				program === Programs.AIEnabledMSK ||
				program === Programs.VirtualPhysicalTherapyAndRehabilitation
		);

		const getEligibleHomePageProgram = programRoute =>
			programRoute.program === userPrimaryProgram;

		const homePageProgramIndex = routes.findIndex(getEligibleHomePageProgram);
		if (userPrimaryProgram && homePageProgramIndex !== -1) {
			const modifiedRoutes = routes.map((route, index) => {
				if (index === homePageProgramIndex) {
					return { ...route, href: '/' };
				}
				return route;
			});
			return modifiedRoutes;
		}
		return routes;
	};

	const getAccessibleRoutes = allLinks => {
		const filteredLinks = allLinks.filter(link => {
			if (isLoggedIn) {
				if (link.isProtectedPage === true) {
					return userHasProgramFeature(link) && userHasProgram(link);
				}
			} else if (link.isProtectedPage === false) {
				return userHasProgramFeature(link);
			}

			return false;
		});

		return filteredLinks;
	};

	const modfifiedSecondNavBarRoutes = setHomeComponentRoute(secondNavBarRoutes);

	return {
		homePageRoute: getAccessibleRoutes(homePageRoutes),
		topNavBarRoutes: getAccessibleRoutes(topNavBarRoutes),
		secondNavBarRoutes: getAccessibleRoutes(modfifiedSecondNavBarRoutes),
		errorPages,
		footerMenu,
		profileRoutes: isLoggedIn ? getAccessibleRoutes(profileRoutes) : null, // Profile links are only available after logging in.
	};
};

export default useNavRoutes;
