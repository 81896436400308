import { Helmet } from 'react-helmet-async';
import { string } from 'prop-types';
import { useLocation } from 'react-router-dom';

const PageSEO = ({ pageTitle, description }) => {
	const location = useLocation();

	return (
		<Helmet>
			<title>{pageTitle} | ASHCare</title>
			<meta name="description" content={description} />
			{/* Open Graph / Facebook */}
			<meta
				property="og:url"
				content={`https://www.ashcare.com${location.pathname}`}
			/>
			<meta property="og:title" content={`${pageTitle} | ASHCare`} />
			<meta property="og:description" content={description} />
			{/* Twitter */}
			<meta
				property="twitter:url"
				content={`https://www.ashcare.com${location.pathname}`}
			/>
			<meta property="twitter:title" content={`${pageTitle} | ASHCare`} />
			<meta property="twitter:description" content={description} />
			<link
				href="https://fonts.googleapis.com/icon?family=Material+Icons&display=block"
				rel="stylesheet"
			/>
			<link
				href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined&display=block"
				rel="stylesheet"
			/>
		</Helmet>
	);
};

PageSEO.propTypes = {
	pageTitle: string.isRequired,
	description: string.isRequired,
};

export default PageSEO;
