import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { NavLinksType } from '../../../scripts/customPropTypes';
import NavigationItem from './NavigationItem';
import List from '@mui/material/List';
import {
	topBarHeight,
	topBarMobileHeight,
} from '../../../scripts/miscConstants';
import { NavListItemButtonMapping } from '../buttons/NavListItemButton';
import useIsMobile from '../../../utils/customHooks/useIsMobile';

const MobileNavMenu = ({ navBarLinks }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const isMobile = useIsMobile();

	const getDrawerChoices = () =>
		navBarLinks.map(
			navBarLink => (
				// TODO: Figre out why TS thinks that activeLinkStyles is required when it's not
				// @ts-ignore
				<NavigationItem
					key={navBarLink.href}
					navLink={navBarLink}
					{...NavListItemButtonMapping}
				>
					{navBarLink.label}
				</NavigationItem>
			)
		);

	return (
		<>
			<IconButton
				color="inherit"
				aria-label="menu"
				aria-haspopup="true"
				onClick={() => setDrawerOpen(prev => !prev)}
			>
				{drawerOpen ? <CloseRoundedIcon /> : <MenuIcon />}
			</IconButton>
			<Drawer
				anchor="left"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
			>
				<List sx={{ pt: `${isMobile ? topBarMobileHeight : topBarHeight}` }}>
					{getDrawerChoices()}
				</List>
			</Drawer>
		</>
	);
};

MobileNavMenu.propTypes = NavLinksType;

export default MobileNavMenu;
